import { IconButton, Icons } from '@shopline/dashboard-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Text from '@/components/Text';
import { EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX } from '@/constants/e2eId';

const LanguageDropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageMenu = styled.div`
  width: auto;
  position: absolute;
  top: 4rem;
  right: 2.5rem;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.SNOW_100};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
`;

const LanguageItem = styled.div`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #f0f7ff;
  }
`;

const LANGUAGES = ['en', 'zh-hant', 'zh-cn'];

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation('common');
  const language = i18n.language;
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const updateLangQueryParam = (language: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', language);
    window.history.pushState(null, '', url.toString());
  };

  const switchLanguage = (language: string) => {
    updateLangQueryParam(language);
    i18n.changeLanguage(language);
    setLanguageDropdownOpen(false);
  };

  return (
    <LanguageDropdownContainer>
      <Text color="INK_600" fontType="Body">
        {t(language)}
      </Text>
      <IconButton
        size={1.5}
        Icon={Icons.Outline.Chevron}
        iconProps={{
          size: 'MEDIUM',
          color: 'INK_600',
          position: languageDropdownOpen ? 'TOP' : 'BOTTOM',
          e2eId: `${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-language-dropdown-chevron`,
        }}
        handleClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
        tooltipProps={{ direction: 'LEFT' }}
      />
      {languageDropdownOpen && (
        <LanguageMenu>
          {LANGUAGES.map((language) => {
            return (
              <LanguageItem
                data-e2e-id={`${EXTERNAL_YT_CHANNEL_CONNECT_E2E_PREFIX}-language-dropdown-item-${language}`}
                key={language}
                onClick={() => switchLanguage(language)}
              >
                <Text color="INK_700" fontType="Body">
                  {t(language)}
                </Text>
              </LanguageItem>
            );
          })}
        </LanguageMenu>
      )}
    </LanguageDropdownContainer>
  );
};

export default LanguageDropdown;
