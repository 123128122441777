import { Button, Icons, TextButton } from '@shopline/dashboard-ui';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Divider from '@/components/Divider';
import { MarginContainer } from '@/components/Layout';
import Text from '@/components/Text';
import { YT_ACCOUNT_LINKING_E2E_PREFIX } from '@/constants/e2eId';
import { type SupportPageLangKey } from '@/constants/localeMapping';
import { useConfig } from '@/contexts/Config';
import { useQueryMerchant } from '@/hooks/useMerchant';
import useQueryInitialState from '@/hooks/useQueryInitialState';
import { getSupportPageLang } from '@/utils/getSupportPageLang';

import BlockContainer from '../BlockContainer';
import Activated from './Activated';
import SetupBlock from './SetupBlock';

interface GmcAndContentApiBlockProps {
  hasRemovePermission?: boolean;
}

export const gmcSetupButtonE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-gmc-setup_button`;
export const contentApiSetupButtonE2eId = `${YT_ACCOUNT_LINKING_E2E_PREFIX}-contentApi-setup_button`;

const getGmcSectionKnowMoreLink = (lang: SupportPageLangKey) => {
  const supportPageLang = getSupportPageLang(lang);
  return `https://support.shoplineapp.com/hc/${supportPageLang}/articles/900004396726`;
};
const getGmcSectionSetupLink = (adminOrigin: string, handle?: string) =>
  `${adminOrigin}/admin/${handle}/edit`;
const getContentApiSectionKnowMoreLink = (lang: SupportPageLangKey) => {
  const supportPageLang = getSupportPageLang(lang);
  return `https://support.shoplineapp.com/hc/${supportPageLang}/articles/23966890906649`;
};
const getContentApiSectionSetupLink = (adminOrigin: string, handle?: string) =>
  `${adminOrigin}/admin/${handle}/apps/apps_store/Google-Merchant-Center-ContentAPI`;

const SectionKnowMore = ({ link }: { link: string }) => {
  const { t } = useTranslation('ytAccountLinking');
  return (
    <TextButton.Primary
      // @ts-ignore
      as="a"
      href={link}
      target="_blank"
      rel="noreferrer noopener"
    >
      {t('Learn More')}
    </TextButton.Primary>
  );
};

const GmcAccountContainer = styled.div`
  padding: 1rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.SNOW_500};
`;

const GmcAndContentApiBlock = ({
  hasRemovePermission = false,
}: GmcAndContentApiBlockProps) => {
  const { data: merchant } = useQueryMerchant({ refetchOnWindowFocus: true });
  const { data: initialState } = useQueryInitialState();
  const { t, i18n } = useTranslation('ytAccountLinking');
  const googleMerchantId = merchant?.googleMerchantId;
  const isContentApiActivated = merchant?.contentApiIntegrated;
  const merchantHandle = initialState?.merchant.handle;
  const { origins } = useConfig();

  return (
    <BlockContainer
      stepNum={1}
      title={t('Store Setup Title')}
      description={t('Store Setup Description')}
    >
      <MarginContainer mt="1.5rem">
        <SetupBlock
          title={t('Store Setup GMC Title')}
          description={
            <Trans
              t={t}
              i18nKey="Store Setup GMC Description"
              components={{
                KnowMoreLink: (
                  <SectionKnowMore
                    link={getGmcSectionKnowMoreLink(
                      i18n.language as SupportPageLangKey,
                    )}
                  />
                ),
              }}
            />
          }
        >
          {googleMerchantId ? (
            <MarginContainer mt="1rem">
              <Text color="INK_800" fontType="Body">
                {t('Store Setup GMC Account Connected')}
              </Text>
              <MarginContainer mt="0.25rem">
                <GmcAccountContainer>
                  <Text color="INK_600" fontType="Body">
                    {googleMerchantId}
                  </Text>
                </GmcAccountContainer>
              </MarginContainer>
              <MarginContainer mt="0.25rem">
                <Activated />
              </MarginContainer>
            </MarginContainer>
          ) : (
            <MarginContainer mt="0.5rem">
              <Button.Default
                // @ts-ignore
                as="a"
                href={getGmcSectionSetupLink(origins.admin, merchantHandle)}
                target="_blank"
                rel="noreferrer noopener"
                Icon={Icons.Outline.Link}
                width="SQUARE"
                disabled={!hasRemovePermission}
                e2eId={gmcSetupButtonE2eId}
              >
                {t('Setup Now')}
              </Button.Default>
            </MarginContainer>
          )}
        </SetupBlock>
      </MarginContainer>
      <Divider spacing="1.5rem" />
      <SetupBlock
        title={t('Store Setup Content API Title')}
        description={
          <Trans
            t={t}
            i18nKey="Store Setup Content API Description"
            components={{
              KnowMoreLink: (
                <SectionKnowMore
                  link={getContentApiSectionKnowMoreLink(
                    i18n.language as SupportPageLangKey,
                  )}
                />
              ),
            }}
          />
        }
      >
        {isContentApiActivated ? (
          <MarginContainer mt="1rem">
            <Text color="INK_800" fontType="Body">
              {t('Store Setup Content API Status')}
            </Text>
            <MarginContainer mt="0.25rem">
              <Activated />
            </MarginContainer>
          </MarginContainer>
        ) : (
          <MarginContainer mt="0.5rem">
            <Button.Default
              // @ts-ignore
              as="a"
              href={getContentApiSectionSetupLink(
                origins.admin,
                merchantHandle,
              )}
              target="_blank"
              rel="noreferrer noopener"
              Icon={Icons.Outline.Link}
              width="SQUARE"
              disabled={!hasRemovePermission}
              e2eId={contentApiSetupButtonE2eId}
            >
              {t('Setup Now')}
            </Button.Default>
          </MarginContainer>
        )}
      </SetupBlock>
    </BlockContainer>
  );
};

export default GmcAndContentApiBlock;
