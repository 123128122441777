import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Icons, TextButton } from '@shopline/dashboard-ui';
import dayjs from 'dayjs';
import { matchesProperty, overSome } from 'lodash-es';
import { FunctionComponent } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BranchLoading } from '@/components/BranchLoading';
import Page from '@/components/Page';
import Section from '@/components/Section';
import { useToastActionContext } from '@/contexts/Toast';
import {
  useQueryLiveRoomSetting,
  useUpdateLiveRoomSetting,
} from '@/hooks/useLiveRoomSetting/useLiveRoomSetting';
import { useQueryMerchantConnectedChannels } from '@/hooks/youtubeAccountLinking/useQueryMerchantConnectedChannels';

import { PRODUCTS_MAX_COUNT, YT_LIVE_E2E_PREFIX } from './constants';
import formSchema, { FormField, FormSchema } from './formSchemaV2';
import useCanEdit from './hooks/useCanEdit';
import useExceedDate, { ExceedDateProvider } from './hooks/useExceedDate';
import LiveChannel from './LiveChannel';
import LiveDate from './LiveDate';
import LiveProducts from './LiveProducts';
import YoutubeAdminPreparation from './YoutubeAdminPreparation';
import YoutubeTermsAndConditions from './YoutubeTermsAndConditions';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CenterWrapper = styled.div`
  text-align: center;
`;

const YTLiveV2: FunctionComponent = () => {
  const { t } = useTranslation('ytLive');
  const { createUIToast } = useToastActionContext();
  const canEdit = useCanEdit();
  const [exceedDate, touch] = useExceedDate();

  const { data: liveRoomSetting } = useQueryLiveRoomSetting();
  const { data: connectedChannels } = useQueryMerchantConnectedChannels();
  const youtubeChannelId = liveRoomSetting?.youtubeChannelId;

  const isSelectedChannelConnected = connectedChannels?.connects?.some(
    (connectedChannel) =>
      connectedChannel.youtubeChannelId === youtubeChannelId,
  );

  // if youtubeChannelId is not null but the channel is not present in connectedChannels, it means the channel is unlinked
  const isPrevSelectedChannelUnlinked =
    Boolean(youtubeChannelId) && !isSelectedChannelConnected;

  const {
    mutate: updateLiveRoomSetting,
    isLoading: isUpdateLiveRoomSettingLoading,
  } = useUpdateLiveRoomSetting();
  const formMethods = useForm({
    resolver: joiResolver(formSchema),
    values: {
      [FormField.channel]: !isPrevSelectedChannelUnlinked
        ? youtubeChannelId
        : '',
      [FormField.date]: liveRoomSetting?.date ?? '',
      [FormField.slProductIds]: liveRoomSetting?.slProductIds ?? [],
      [FormField.tnc]: false,
    },
  });

  const liveProductsErrorMessage = t(
    (formMethods.formState.errors[FormField.slProductIds]?.message ??
      '') as any,
    {
      count: PRODUCTS_MAX_COUNT,
    },
  );

  const onSubmit: SubmitHandler<FormSchema> = (data) => {
    if (liveRoomSetting?.date && new Date(liveRoomSetting.date) <= new Date()) {
      createUIToast?.({
        type: 'alert',
        titleWithParams: {
          key: 'exceeded the LIVE Broadcast time',
          params: { ns: 'ytLive' },
        },
      });
      touch();
      return;
    }
    const { date, slProductIds, channel } = data;
    updateLiveRoomSetting({
      date: dayjs(date).toISOString(),
      slProductIds,
      youtubeChannelId: channel,
    });
  };

  const isYoutubeTncChecked = formMethods.watch(FormField.tnc);

  const shouldDisableSaveButton =
    !canEdit || exceedDate || !isYoutubeTncChecked;

  return (
    <Page titleLeftSection={t('Setup Guide YouTube Shopping Setup')}>
      {/* @ts-ignore react 18 removed `children` as a default prop  */}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit as any)}>
          <SectionContainer>
            <Section
              title={t('Setup Guide Choose Channel Title')}
              caption={t('Setup Guide Choose Channel Description')}
            >
              <LiveChannel
                isPrevSelectedChannelUnlinked={isPrevSelectedChannelUnlinked}
              />
            </Section>
            <Section
              title={t('Schedule Live Stream Title')}
              caption={t('Schedule Live Stream Description')}
            >
              <LiveDate />
            </Section>
            <Section
              title={`${t('Live Products')} ${t('Live Products - counter', {
                count: formMethods.watch(FormField.slProductIds).length,
                max: PRODUCTS_MAX_COUNT,
              })}`}
              caption={t('Live Stream Products Description')}
              errorMessage={liveProductsErrorMessage}
            >
              <LiveProducts />
            </Section>
            <Section
              title={t('Youtube Admin Preparation')}
              caption={t('YouTube Preparation Description')}
            >
              <YoutubeAdminPreparation />
            </Section>
            <Section
              title={t('Finish Setting')}
              caption={<YoutubeTermsAndConditions />}
            >
              <CenterWrapper>
                <Button.Primary
                  type="submit"
                  width="WIDE"
                  isLoading={isUpdateLiveRoomSettingLoading}
                  disabled={shouldDisableSaveButton}
                  e2eId={`${YT_LIVE_E2E_PREFIX}-save_button`}
                >
                  {t('Save', { ns: 'common' })}
                </Button.Primary>
              </CenterWrapper>
            </Section>
            {liveRoomSetting?.youtubeUrl && (
              <CenterWrapper>
                <TextButton.Primary
                  // @ts-ignore
                  as="a"
                  href={liveRoomSetting?.youtubeUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icons.Outline.Link size="MEDIUM" />
                  {t('Start Streaming')}
                </TextButton.Primary>
              </CenterWrapper>
            )}
          </SectionContainer>
        </form>
      </FormProvider>
    </Page>
  );
};

const isLoadingLiveRoomSetting = overSome([
  matchesProperty('isLoading', true),
  matchesProperty('isSuccess', false),
]);

const EnhancedYTLive = () => {
  const query = useQueryLiveRoomSetting();
  const isLoading = isLoadingLiveRoomSetting(query);

  return (
    <BranchLoading isLoading={isLoading}>
      <ExceedDateProvider>
        <YTLiveV2 />
      </ExceedDateProvider>
    </BranchLoading>
  );
};

export default EnhancedYTLive;
